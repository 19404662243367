<template>
  <div class="ui container" style="margin-bottom: 3em">
    <div>
      <h1 class="ui header" style="float: left">Termine</h1>

      <router-link
        :to="{ name: 'newEvent' }"
        class="ui primary button"
        style="margin-bottom: 1em; float: right; margin-right: 0"
      >
        Neuen Termin erstellen
      </router-link>
      <div class="clear" style="clear: both"></div>
    </div>

    <EventList :events="upcoming" />

    <p class="headline--meta" style="margin-top: 2em">
      <i class="history icon"></i> Vergangene Termine
    </p>
    <EventList :events="past" />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import EventList from "@/components/lists/EventList.vue";

export default {
  name: "Events",
  components: {
    EventList,
  },
  computed: {
    ...mapGetters({
      events: "event/once",
    }),
    upcoming() {
      return this.events.filter((event) => {
        const startAt = Vue.moment.unix(event.startAt.seconds);
        const today = Vue.moment();
        return startAt.diff(today, "days") >= 0;
      });
    },
    past() {
      return this.events.filter((event) => {
        const startAt = Vue.moment.unix(event.startAt.seconds);
        const today = Vue.moment();
        return startAt.diff(today, "days") < 0;
      });
    },
  },
};
</script>
