<template>
  <form
    class="ui form"
    :class="{ loading: loading }"
    @submit.prevent="saveEvent"
    @change="markFormAsDirty"
  >
    <div
      :class="{ 'ui secondary pointing menu': event.isRecurring }"
      style="margin-bottom: 2em"
    >
      <a class="active item" data-tab="info" v-if="event.isRecurring"
        >Termin bearbeiten</a
      >
    </div>
    <div class="ui active tab" data-tab="info">
      <div class="ui stackable grid">
        <!-- [START] Title, Content -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Stammdaten</h3>
            <span class="meta"
              >Bearbeite hier die Stammdaten des Termins ein.</span
            >
          </div>
          <div class="twelve wide column">
            <!-- [START] Title -->
            <div class="field required">
              <label>Titel</label>
              <input
                type="text"
                placeholder="Titel des Termins"
                name="title"
                :value="event ? event.title : null"
                style="padding: 8px 15px; font-size: 24px; line-height: 36px"
              />
            </div>
            <!-- [END] Title -->
            <!-- [START] Content -->
            <div class="field required">
              <label>Beschreibung</label>
              <form-editor :editor="editor" />
            </div>
            <!-- [END] Content -->
            <!-- [START] Excerpt -->
            <div class="field required">
              <label>Auszug</label>
              <editor-content :editor="editorExcerpt" class="editor" />
              <p class="meta" style="margin-top: 4px">
                Der Auszug erscheint in der Seitenleiste auf der Startseite und
                sollte max. 250 Zeichen lang sein.
              </p>
            </div>
            <!-- [END] Excerpt -->
          </div>
        </div>
        <!-- [END] Title, Content -->
        <!-- [START] Dates, Intervals -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Uhrzeit und Datum</h3>
            <span class="meta"
              >Trage hier Uhrzeit und Datum des Termins ein.</span
            >
          </div>
          <div class="twelve wide column">
            <!-- [START] Date Configuration -->
            <div class="ui grid row">
              <div class="eight wide column">
                <div class="two fields">
                  <!-- [START] Start Date -->
                  <div class="field required">
                    <label>Startdatum</label>
                    <input
                      type="date"
                      name="startDate"
                      placeholder="Datum wählen…"
                      :value="startDate ? startDate : null"
                      :min="startDate ? startDate : today"
                      required
                    />
                  </div>
                  <div v-if="!event.isAllDay">
                    <div class="field required">
                      <label>Startzeit</label>
                      <input
                        type="time"
                        name="startTime"
                        placeholder="Uhrzeit wählen…"
                        steps="1"
                        :value="startTime ? startTime : null"
                        required
                      />
                    </div>
                  </div>
                  <!-- [END] Start Date -->
                </div>
              </div>
              <div class="eight wide column">
                <div class="two fields">
                  <div class="ui field required">
                    <label>Enddatum</label>
                    <input
                      type="date"
                      name="endDate"
                      placeholder="Datum wählen…"
                      steps="1"
                      :min="startDate ? startDate : today"
                      :value="endDate ? endDate : null"
                    />
                  </div>
                  <div class="ui field" v-if="!event.isAllDay">
                    <label>Endzeit</label>
                    <input
                      type="time"
                      name="startTime"
                      placeholder="Uhrzeit wählen…"
                      steps="1"
                      :value="endTime ? endTime : null"
                    />
                  </div>
                </div>
              </div>
              <div class="field" style="margin-top: -10px; margin-bottom: 10px">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    tabindex="0"
                    class="hidden"
                    name="isAllDay"
                    :checked="event.isAllDay"
                  />
                  <label>Ganztägiger Termin</label>
                </div>
              </div>
            </div>
          </div>
          <!-- [END] Date Configuration -->
        </div>
        <!-- [END] Dates, Intervals -->

        <!-- [START] Layout -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Titelbild</h3>
            <span class="meta"
              >Wähle ein Titelbild für diesen Beitrag aus.</span
            >
          </div>
          <div class="six wide column">
            <!-- [START] Header Image -->
            <div class="field" v-if="this.event">
              <label>Titelbild</label>
              <ImageTitleSelect
                :sourceImage="this.event.imageUrl"
                :targetImage="this.imageUploadPath"
                v-on:setImage="setImage"
                v-on:setImageUploading="setImageUploading"
                v-on:deleteImage="deleteImage"
              />
              <p class="meta" style="margin-top: 10px">
                Kein Bild? Kostenlose Bilder findest du z.B. auf
                <a href="https://unsplash.com/" target="_blank">Unsplash</a>.
              </p>
            </div>
            <!-- [END] Header Image -->
          </div>
        </div>
        <!-- [End] Layout -->

        <!-- [START] Status -->
        <div class="row">
          <div class="four wide column">
            <h3 class="header">Status</h3>
            <span class="meta">Lege die Sichtbarkeit des Termins fest.</span>
          </div>
          <div class="five wide column">
            <div class="field required">
              <label>Status</label>
              <select name="visibility" class="ui dropdown" required>
                <option value="">Status wählen…</option>
                <option value="draft">
                  <span class="ui empty circular label gray"></span> Entwurf
                </option>
                <option value="review">
                  <span class="ui empty circular label yellow"></span>
                  Ausstehende Freigabe
                </option>
                <option value="published">
                  <span class="ui empty circular label olive"></span>
                  Veröffentlicht
                </option>
              </select>
            </div>
          </div>
          <!-- [END] Status -->
        </div>
        <div
          class="row"
          style="
            border-top: 1px solid rgba(34, 36, 38, 0.15);
            padding-top: 20px;
            margin-top: 20px;
          "
        >
          <div class="sixteen wide column">
            <div style="float: left" v-if="event.id">
              <button
                class="ui basic button red fluid"
                style="margin-bottom: 1em; margin-right: 0; cursor: pointer"
                @click.prevent="showDeleteModal"
              >
                Termin löschen
              </button>
            </div>
            <div style="float: right">
              <button
                type="submit"
                class="ui primary button fluid"
                :disabled="!isDirty"
                style="
                  margin-bottom: 1em;
                  float: right;
                  margin-right: 0;
                  cursor: pointer;
                "
              >
                {{ event ? "Speichern" : "Erstellen" }}
              </button>
            </div>
          </div>
          <!-- [START] Buttons -->
        </div>
      </div>
    </div>

    <!-- Modal Delete -->
    <ModalDelete
      :isModalShowing.sync="isModalShowing"
      :isModalConfirmed.sync="isModalConfirmed"
      @modalConfirmed="deleteEvent"
      @modalCancelled="modalCancelled"
    ></ModalDelete>
  </form>
</template>

<script>
import { Editor, EditorContent } from "tiptap";
import {
  Blockquote,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Underline,
  History,
  Heading,
} from "tiptap-extensions";
import FormEditor from "@/components/forms/FormEditor.vue";
import ModalDelete from "@/components/modals/ModalDelete.vue";
import ImageTitleSelect from "@/components/ImageTitleSelect.vue";

export default {
  name: "EventForm",
  props: ["event"],
  components: {
    ModalDelete,
    ImageTitleSelect,
    EditorContent,
    FormEditor,
  },
  data() {
    return {
      loading: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Link(),
          new Underline(),
          new History(),
          new Heading({ levels: [1, 2, 3] }),
        ],
        linkUrl: null,
        linkMenuIsActive: false,
        onUpdate: ({ getJSON, getHTML }) => {
          if (
            this.draftPost &&
            this.draftPost.contentHtml &&
            this.draftPost.contentHtml != this.event.content
          ) {
            this.markFormAsDirty();
          }
          this.draftPost.contentJson = getJSON();
          this.draftPost.contentHtml = getHTML();
        },
        content: this.event ? this.event.content : null,
      }),
      editorExcerpt: new Editor({
        linkUrl: null,
        linkMenuIsActive: false,
        onUpdate: ({ getJSON, getHTML }) => {
          if (
            this.draftPostExcerpt &&
            this.draftPostExcerpt.contentHtml &&
            this.draftPostExcerpt.contentHtml != this.event.excerpt
          ) {
            this.markFormAsDirty();
          }
          this.draftPostExcerpt.contentJson = getJSON();
          this.draftPostExcerpt.contentHtml = getHTML();
        },
        content: this.event
          ? this.event.excerpt
          : this.draftPostExcerpt
          ? this.draftPostExcerpt.contentHtml
          : null,
      }),
      isDirty: false,
      isModalShowing: false,
      isModalConfirmed: false,
      nextEvents: null,
      imageUploadPath: null,
      imageUploading: false,
      draftPost: {
        contentJson: null,
        contentHtml: null,
        contentDate: null,
      },
      draftPostExcerpt: {
        contentJson: null,
        contentHtml: null,
        contentDate: null,
      },
    };
  },
  computed: {
    today() {
      return this.$moment.utc(this.$moment()).local().format("YYYY-MM-DD");
    },
    startDate() {
      if (!this.event || !this.event.startAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.startAt.seconds * 1000)
        .local()
        .format("YYYY-MM-DD");
    },
    startTime() {
      if (!this.event || !this.event.startAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.startAt.seconds * 1000)
        .local()
        .format("HH:mm");
    },
    endDate() {
      if (!this.event || !this.event.endAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.endAt.seconds * 1000)
        .local()
        .format("YYYY-MM-DD");
    },
    endTime() {
      if (!this.event || !this.event.endAt) {
        return null;
      }
      return this.$moment
        .utc(this.event.endAt.seconds * 1000)
        .local()
        .format("HH:mm");
    },
  },
  mounted() {
    $(".ui.checkbox").checkbox();
    $(".ui.dropdown").dropdown();
    this.initEditor();

    if (this.event) {
      if (this.event.startAt) {
        this.draftPost.contentDate = this.$moment(
          this.event.startAt.seconds
        ).format("YYYY-MM-DDTHH:mm");
      }

      $("[name=visibility]").dropdown(
        "set selected",
        this.event && this.event.visibility ? this.event.visibility : "draft"
      );

      this.imageUploadPath = this.event.imageUrl;
    }
  },
  methods: {
    initEditor() {
      this.isDirty = false;
    },
    setImage(uploadPath) {
      this.imageUploadPath = uploadPath;
      this.isDirty = true;
    },
    setImageUploading(uploading) {
      this.imageUploading = uploading;
    },
    deleteImage() {
      this.imageUploadPath = null;
      this.isDirty = true;
    },
    saveEvent() {
      const title = $.trim($("[name=title]").val());
      const content = $.trim(
        this.draftPost.contentHtml
          ? this.draftPost.contentHtml
          : this.event.content
      );
      const excerpt = $.trim(
        this.draftPostExcerpt.contentHtml
          ? this.draftPostExcerpt.contentHtml
          : this.event.excerpt
      );
      let startAt = $.trim($("[name=startDate]").val());
      let endAt = $.trim($("[name=endDate]").val());
      let startTime = $.trim($("[name=startTime]").val());
      let endTime = $.trim($("[name=endTime]").val());
      const isAllDay = $(".ui.checkbox").checkbox("is checked");
      const visibility = $.trim($("[name=visibility]").val());

      // Add time to given start timestamp
      if (startAt && startTime.length > 0) {
        startTime = this.$moment(startTime, "HH:mm");
        startAt = this.$moment(startAt)
          .add(startTime.hour(), "hour")
          .add(startTime.minute(), "minute");
      } else if (startAt.length > 0) {
        startAt = this.$moment(startAt);
      } else if (!startAt || startAt.length === 0) {
        startAt = null;
      }

      // Add time to given end timestamp
      if (endAt && endTime.length > 0) {
        endTime = this.$moment(endTime, "HH:mm");
        endAt = this.$moment(endAt)
          .add(endTime.hour(), "hour")
          .add(endTime.minute(), "minute");
      } else if (endAt.length > 0) {
        endAt = this.$moment(endAt);
      } else if (!endAt || endAt.length === 0) {
        endAt = null;
      }

      const payload = {
        title: title,
        content: content,
        excerpt: excerpt,
        startAt: startAt,
        endAt: endAt,
        isRecurring: false,
        isAllDay: isAllDay,
        visibility: visibility,
        imageUrl: this.imageUploadPath || null,
      };

      // Check if event is a multi day event
      if (endAt.diff(startAt, "days") > 0) {
        payload["isMultiDay"] = true;
      }

      this.loading = true;
      if (!this.event.id) {
        const self = this;

        return this.$store
          .dispatch("event/create", {
            payload: payload,
          })
          .then((result) => {
            this.isDirty = false;
            this.$emit("isDirty", this.isDirty);

            this.isModalConfirmed = true;
            self.$router.push({ name: "event", params: { id: result.id } });
          })
          .catch((error) => console.log("Error creating event", error))
          .finally(() => (this.loading = false));
      } else {
        return this.$store
          .dispatch("event/update", {
            eventId: this.event.id,
            payload: payload,
          })
          .then(() => {
            this.isDirty = false;
            this.$emit("isDirty", this.isDirty);
          })
          .catch((error) => console.log("Error updating event", error))
          .finally(() => (this.loading = false));
      }
    },
    deleteEvent() {
      const self = this;
      this.loading = true;

      this.isDirty = false;
      this.$emit("isDirty", this.isDirty);
      this.isModalConfirmed = true;

      this.$store
        .dispatch("event/delete", { eventId: this.event.id })
        .then(() => {
          self.$router.push({ name: "events" });
        })
        .catch((error) => console.log("Error deleting event", error))
        .finally(() => (this.loading = false));
    },
    markFormAsDirty() {
      this.isDirty = true;
      this.$emit("isDirty", this.isDirty);
    },
    showDeleteModal() {
      this.isModalConfirmed = false;
      this.isModalShowing = true;
    },
    modalCancelled() {
      this.isModalConfirmed = false;
      this.isModalShowing = false;
    },
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 5px;
}
</style>
