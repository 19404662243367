<template>
  <div class="ui container" style="margin-bottom: 2em">
    <!-- [START] Breadcrumb -->
    <div style="margin-bottom: 2em">
      <router-link :to="{ name: 'events' }">
        <i class="arrow left icon" />
        <strong>Alle Termine</strong>
      </router-link>
      <h1 style="margin-top: 6px">
        {{
          event && event.title ? "Termin bearbeiten" : "Neuen Termin erstellen"
        }}
      </h1>
      <hr style="margin-top: 1em" />
    </div>
    <!-- [END] Breadcrumb -->

    <!-- [START] Form -->
    <EventForm v-if="event" :event="event" @isDirty="isDirty" />
    <!-- [END] Form -->

    <!-- [START] Modal Cancel -->
    <ModalCancel
      :isModalShowing.sync="isModalShowing"
      :isModalConfirmed.sync="isModalConfirmed"
      @isDirty="isDirty(index, ...arguments)"
      @cancelModalConfirmed="modalConfirmed"
    ></ModalCancel>
    <!-- [END] Modal Cancel -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventForm from "@/components/forms/EventForm.vue";
import ModalCancel from "@/components/modals/ModalCancel.vue";

export default {
  name: "Event",
  props: ["id"],
  components: {
    EventForm,
    ModalCancel,
  },
  data() {
    return {
      isModalShowing: false,
      isModalConfirmed: false,
      isFormDirty: false,
      cancelRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      eventById: "event/eventById",
    }),
    event() {
      if (!this.id) {
        return {
          title: null,
          content: null,
          startAt: null,
          endAt: null,
          isRecurring: false,
          isAllDay: false,
          visibility: "draft",
        };
      }
      return this.eventById(this.id);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isModalConfirmed || !this.isFormDirty) {
      next();
    } else {
      this.isModalShowing = true;
      this.isModalConfirmed = false;
      this.cancelRoute = to;
      next(false);
    }
  },
  methods: {
    isDirty(isDirty) {
      this.isFormDirty = isDirty;
    },
    modalConfirmed() {
      this.isModalConfirmed = true;
      this.isModalShowing = false;
      this.$router.push(this.cancelRoute);
    },
  },
};
</script>
